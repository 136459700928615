export const environment = {
  production: true,
  apiEndpoint: 'https://api.atompower.com/ev/2021-09-03/internal',
  tokenEndpoint: 'https://api.atompower.com/ev/2021-09-03/auth/login',
  optEndpoint: 'https://api.atompower.com/ev/2021-09-03/auth/verify',
  refreshEndpoint: 'https://api.atompower.com/ev/2021-09-03/auth/refresh',
  tokenValidate: '',
  requireHttps: true,
  showDebugInformation: false,
  protocol: 'https',
  websocketProtocol: 'wss',
};
